import React, { useState, useEffect } from "react";
import axios from 'axios';
import config from '../../config/config';
import Toast from '../../config/Toast';
import "./copyDatabase.css";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import HeaderRoutes from '../../components/HeaderRoutes/HeaderRoutes';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary, {
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Tooltip from '@mui/material/Tooltip';


const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  marginBottom: '20px',
  borderRadius: '5px',
  boxShadow: '0 0 16px rgba(0, 0, 0, 0.25)',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '5px',
  flexDirection: 'row',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 30,
    position: 'relative',
    border: '1px solid',
    borderColor: '#cccccc',
    fontSize: 16,
    width: '100%',
    height: '32px',
    padding: '4px 0px',
    paddingLeft: '12px',
    paddingRight: '40px',
    marginRight: '10px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: '#555555',
    },
  },
}));
const BootstrapInputBox = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(1),
  },
  '& .MuiInputBase-input': {
    borderRadius: 40,
    position: 'relative',
    backgroundColor: '#fff',
    fontSize: 16,
    width: '100%',
    height: '32px',
    paddingLeft: '20px',
    paddingRight: '40px',
    marginBottom: '15px',
    border: '1px solid #cccccc',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      borderColor: '#555555',
    },
  },
}));

const CustomToolbar1 = ({ onSearchChange }) => {
  return (
    <GridToolbarContainer>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ height: '48px', width: '100%', borderBottom: '1px solid #dddddd' }}>
          <p className="py-2 px-4" style={{ fontSize: '20px', fontWeight: 700 }}>Live Databases</p>
        </Box>
        <div style={{ position: 'absolute', left: '62%', top: '1.5%', transition: '1s linear' }}>
          <FormControl variant="standard">
            <BootstrapInput
              placeholder="Search"
              id="bootstrap-input"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <img
              src="/images/search-new.svg"
              alt="Search Icon"
              style={{
                position: 'absolute',
                right: '20px',
                top: '10px',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
              }}
            />
          </FormControl>
        </div>
      </div>
    </GridToolbarContainer>
  );
};
const CustomToolbar2 = ({ onSearchChange }) => {
  return (
    <GridToolbarContainer>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
        <Box sx={{ height: '48px', width: '100%', borderBottom: '1px solid #dddddd' }}>
          <p className="py-2 px-4" style={{ fontSize: '20px', fontWeight: 700 }}>Test Databases</p>
        </Box>
        <div style={{ position: 'absolute', left: '62%', top: '1.5%', transition: '1s linear' }}>
          <FormControl variant="standard">
            <BootstrapInput
              placeholder="Search"
              id="bootstrap-input"
              onChange={(e) => onSearchChange(e.target.value)}
            />
            <img
              src="/images/search-new.svg"
              alt="Search Icon"
              style={{
                position: 'absolute',
                right: '20px',
                top: '10px',
                cursor: 'pointer',
                width: '20px',
                height: '20px',
              }}
            />
          </FormControl>
        </div>
      </div>
    </GridToolbarContainer>
  );
};
const CustomToolbar3 = ({ onSearchChange }) => {
  return (
    <GridToolbarContainer>
      <Grid item xs={12}>
        <FormControl variant="standard">
          <BootstrapInputBox
            placeholder="Search"
            id="bootstrap-input"
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <img
            src="/images/search-new.svg"
            alt="Search Icon"
            style={{
              position: 'absolute',
              right: '15px',
              top: '13px',
              cursor: 'pointer',
            }}
          />
        </FormControl>
      </Grid>
    </GridToolbarContainer>
  );
};
const CustomToolbar4 = ({ onSearchChange }) => {
  return (
    <GridToolbarContainer>
      <Grid item xs={12}>
        <FormControl variant="standard">
          <BootstrapInputBox
            placeholder="Search"
            id="bootstrap-input"
            onChange={(e) => onSearchChange(e.target.value)}
          />
          <img
            src="/images/search-new.svg"
            alt="Search Icon"
            style={{
              position: 'absolute',
              right: '15px',
              top: '13px',
              cursor: 'pointer',
            }}
          />
        </FormControl>
      </Grid>
    </GridToolbarContainer>
  );
};
const RadioButton = ({ selected }) => {
  return (
    <Box
      component="img"
      src={selected ? "/images/after-checkbox.svg" : "/images/before-checkbox.svg"}
      width='20px'
      height='20px'
      style={{ cursor: 'pointer' }}
    />
  );
};

const CopyDatabase = (row) => {
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRow2, setSelectedRow2] = useState(null);
  const [expanded, setExpanded] = useState('panel1');
  const [open, setOpen] = useState(false);
  const [rowsLive, setRowsLive] = useState([]);
  const [rowsTest, setRowsTest] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQuery2, setSearchQuery2] = useState('');
  const [note, setNote] = useState('');
  const [lock, setLock] = useState(false);
  const [confirmNote, setConfirmNote] = useState(selectedRow2?.id);
  const [errorMessage, setErrorMessage] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [type, setType] = useState('');

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const columns = [
    {
      field: 'icon',
      width: 10,
      renderCell: () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box component="img" src="/images/greenIcon.svg" alt="icon" />
        </Box>
      ),
    },
    {
      field: 'id',
      width: 110,
      sortable: false,
      renderCell: (params) => <span style={{ fontSize: '14px', fontWeight: 600 }}>{params.value}</span>
    },
    {
      field: 'department_name',
      width: 320,
      sortable: false,
      renderCell: (params) => <span style={{ fontSize: '14px', fontWeight: 600 }}>{params.value}</span>
    },
    {
      field: 'checkbox',
      width: 80,
      sortable: false,
      renderCell: (params) => <RadioButton selected={params.id === selectedRow?.id} />,
    },
  ];
  useEffect(() => {
    const LiveDatabase = async () => {
      const data = {
        type: "LIVE"
      };
      let path = config.authServiceUrl;
      path = path + 'database/list';
      const token = localStorage.getItem("tokenId");

      try {
        const response = await axios.post(path, data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
        console.log('Response for Live Database:', response.data);
        setRowsLive(response.data.data);
      } catch (error) {
        console.log('Error fetching Live-database:', error.response.data.message);
      }
    };
    LiveDatabase();
  }, []);
  const filteredRows = rowsLive.filter((row) =>
    Object.values(row).some((value) => value && value.toString().toLowerCase().includes(searchQuery.toLowerCase()))
  );
  const handleRowClick = (params) => {
    setSelectedRow(params.row);
  };


  const columnss = [
    {
      field: 'icon',
      width: 1,
      renderCell: () => (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <Box component="img" src="/images/yellow-icon.svg" alt="icon" />
        </Box>
      ),
    },
    {
      field: 'id',
      width: 110,
      sortable: false,
      renderCell: (params) => <span style={{ fontSize: '14px', fontWeight: 600 }}>{params.value}</span>
    },
    {
      field: 'department_name',
      width: 260,
      sortable: false,
      renderCell: (params) => <span style={{ fontSize: '14px', fontWeight: 600 }}>{params.value}</span>
    },
    {
      field: 'iconn',
      width: 1,
      cellClassName: 'lock',
      renderCell: (params) => {
        return (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            {params?.row?.db_overview?.is_locked ? (
              params?.row?.db_overview?.notes === null ? (
                <Tooltip title={`Locked by ${params?.row?.db_overview?.user?.name}`} placement="top" arrow>
                  <Box component="img" src="/images/test-lock.svg" alt="Locked Icon" />
                </Tooltip>
              ) : (
                <Tooltip title={`Locked by ${params?.row?.db_overview?.user?.name} with a note ${params?.row?.db_overview?.notes}`} placement="top" arrow>
                  <Box component="img" src="/images/test-lock.svg" alt="Locked Icon" />
                </Tooltip>
              )
            ) : (
              <Box component="img" src="/images/unlocked-icon.svg" alt="Unlocked Icon" />
            )}
          </Box>
        );
      },
    },
    {
      field: 'checkbox',
      width: 80,
      sortable: false,
      renderCell: (params) => <RadioButton selected={params.id === selectedRow2?.id} />,
      cellClassName: 'data-grid-column',
    },
  ];
  useEffect(() => {
    const TestDatabase = async () => {
      const data = {
        type: "TEST"
      };
      let path = config.authServiceUrl;
      path = path + 'database/list';
      const token = localStorage.getItem("tokenId");

      try {
        const response = await axios.post(path, data, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          }
        });
        console.log('Response for Test Database:', response.data);
        setRowsTest(response.data.data);
      } catch (error) {
        console.log('Error fetching Test-database:', error.response.data.message);
      }
    };
    TestDatabase();
  }, []);
  const filteredRowss = rowsTest.filter((row) =>
    Object.values(row).some((value) => value && value.toString().toLowerCase().includes(searchQuery2.toLowerCase()))
  );
  const handleRowClick2 = (params) => {
    if (!params?.row?.db_overview?.is_locked) {
      setSelectedRow2(params.row);
    } else {
      {
        params?.row?.db_overview?.notes === null ?
          (
            setMessage(`Locked by ${params?.row?.db_overview?.user?.name}`)
          ) :
          setMessage(`Locked by ${params?.row?.db_overview?.user?.name} with a note ${params?.row?.db_overview?.notes}`);
      }
      setType('error');
      setSnackbarOpen(true);
    }
  };

  const submitData = async () => {
    if (confirmNote !== selectedRow2?.id) {
      setMessage('Id does not matched');
      setType('error');
      setSnackbarOpen(true);
      return;
    }
    const data = {
      db_from: selectedRow.id,
      db_to: selectedRow2.id,
      is_locked: lock,
      note: note,
    };
    let path = config.authServiceUrl + 'database/copy';
    const token = localStorage.getItem("tokenId");

    try {
      const response = await axios.post(path, data, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Response for Copy Data:', response.data);
      setMessage('Copied Successfully');
      setType('success');
      setSnackbarOpen(true);
      setOpen(false);
      handleRefresh();
    } catch (error) {
      console.log('Error fetching Copy Data:', error.response.data.message);
      setErrorMessage(error.response.data.message);
      setMessage(error.response.data.message);
      setType('error');
      setSnackbarOpen(true);
      setOpen(false);
      handleRefresh();
    }
  };

  const handleNote = (event) => {
    setNote(event.target.value);
  };
  const handleConfirmNote = (event) => {
    setConfirmNote(event.target.value);
  };

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const handleCheckbox = () => {
    setLock(!lock);
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <HeaderRoutes />
      <div className="data-base-container" >
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Grid container className="copy-database">
            <Typography style={
              {
                fontSize: '30px',
                fontWeight: 700,
                color: '#000'
              }
            }>Copy Databases</Typography>
          </Grid>
        </div>

        <Box className='copy-database-container'>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Box
                className="datagrid-work"
                sx={{
                  height: '352px',
                  width: '100%',
                  backgroundColor: '#fff',
                  marginTop: '32px',
                  borderRadius: '10px',
                  boxShadow: '0 0 16px rgba(0, 0, 0, 0.25)',
                }}
              >
                <DataGrid
                  rows={filteredRows}
                  columns={columns}
                  disableColumnMenu
                  hideFooterPagination
                  hideFooter
                  disableSelectionOnClick
                  components={{
                    Toolbar: CustomToolbar1,
                  }}
                  componentsProps={{
                    toolbar: {
                      onSearchChange: setSearchQuery,
                    },
                  }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage === 0 ? 'first-row' : ''
                  }
                  onRowClick={handleRowClick}
                  sx={{
                    '& .MuiDataGrid-row': {
                      backgroundColor: (params) =>
                        selectedRow === params.id ? 'rgba(0, 0, 255, 0.1)' : 'none',
                    },
                    cursor: 'pointer',
                    '& .MuiDataGrid-cell': {
                      borderBottom: 'none',
                      paddingLeft: '27px',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                  }}
                />
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box
                className="datagrid-work"
                sx={{
                  height: '352px',
                  width: '100%',
                  backgroundColor: '#fff',
                  marginTop: '32px',
                  borderRadius: '10px',
                  boxShadow: '0 0 16px rgba(0, 0, 0, 0.25)',
                }}
              >
                <DataGrid
                  rows={filteredRowss}
                  columns={columnss}
                  disableColumnMenu
                  hideFooterPagination
                  hideFooter
                  disableSelectionOnClick
                  components={{
                    Toolbar: CustomToolbar2,
                  }}
                  componentsProps={{
                    toolbar: {
                      onSearchChange: setSearchQuery2,
                    },
                  }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage === 0 ? 'first-row' : ''
                  }
                  onRowClick={handleRowClick2}
                  sx={{
                    '& .MuiDataGrid-row': {
                      backgroundColor: (params) => selectedRow2 === params.id ? 'rgba(0, 0, 255, 0.1)' : 'none',
                    },
                    cursor: 'pointer',
                    '& .MuiDataGrid-cell': {
                      borderBottom: 'none',
                      paddingLeft: '27px',
                    },
                    '& .MuiDataGrid-cell:focus': {
                      outline: 'none',
                    },
                    '& .MuiDataGrid-cell:focus-within': {
                      outline: 'none',
                    },
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <Box className='copy-database-container-mobile mt-3'>
          <div style={{ maxWidth: '89vw', overflowX: 'auto', }}>
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                <Typography style={{
                  fontFamily: 'Karla',
                  fontStyle: 'normal',
                  fontSize: '18px',
                  color: '#000000',
                  fontWeight: '700',
                  lineHeight: '150%'
                }}>Live Databases</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <Box
                    className="datagrid-work"
                    sx={{
                      height: '312px',
                      width: '100%',
                    }}
                  >
                    <DataGrid
                      rows={filteredRows}
                      columns={columns}
                      disableColumnMenu
                      hideFooterPagination
                      hideFooter
                      disableSelectionOnClick
                      components={{
                        Toolbar: CustomToolbar3,
                      }}
                      componentsProps={{
                        toolbar: {
                          onSearchChange: setSearchQuery,
                        },
                      }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage === 0 ? 'first-row' : ''
                      }
                      onRowClick={handleRowClick}
                      sx={{
                        '& .MuiDataGrid-row': {
                          backgroundColor: (params) =>
                            selectedRow === params.id ? 'rgba(0, 0, 255, 0.1)' : 'none',
                        },
                        cursor: 'pointer',
                        '& .MuiDataGrid-cell': {
                          borderBottom: 'none',
                          paddingLeft: '27px',
                          backgroundColor: 'none'
                        },
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                          outline: 'none',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                <Typography style={{
                  fontFamily: 'Karla',
                  fontStyle: 'normal',
                  fontSize: '18px',
                  color: '#000000',
                  fontWeight: '700',
                  lineHeight: '150%'
                }}>Test Databases</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <Box
                    className="datagrid-work"
                    sx={{
                      height: '312px',
                      width: '100%',
                    }}
                  >
                    <DataGrid
                      rows={filteredRowss}
                      columns={columnss}
                      disableColumnMenu
                      hideFooterPagination
                      hideFooter
                      disableSelectionOnClick
                      components={{
                        Toolbar: CustomToolbar4,
                      }}
                      componentsProps={{
                        toolbar: {
                          onSearchChange: setSearchQuery2,
                        },
                      }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage === 0 ? 'first-row' : ''
                      }
                      onRowClick={handleRowClick2}
                      sx={{
                        '& .MuiDataGrid-row': {
                          backgroundColor: (params) => selectedRow2 === params.id ? 'rgba(0, 0, 255, 0.1)' : 'none',
                        },
                        cursor: 'pointer',
                        '& .MuiDataGrid-cell': {
                          borderBottom: 'none',
                          paddingLeft: '27px',
                          backgroundColor: 'none'
                        },
                        '& .MuiDataGrid-cell:focus': {
                          outline: 'none',
                        },
                        '& .MuiDataGrid-cell:focus-within': {
                          outline: 'none',
                        },
                      }}
                    />
                  </Box>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </div>
        </Box>

        <Box mt={3}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <p className="mt-0">
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {selectedRow ?
                  <>
                    <Box
                      component="img"
                      src="/images/live-database-icon.svg"
                      alt="Icon"
                      sx={{ cursor: 'pointer', marginLeft: '13px' }}
                    />
                    {selectedRow && <span style={{ marginLeft: '10px' }}>{selectedRow.id}</span>}
                  </>
                  : ''}
              </div>
            </p>
            <p>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {selectedRow2 ?
                  <>
                    <Box
                      component="img"
                      src="/images/arrow-icon.svg"
                      alt="Icon"
                      sx={{ cursor: 'pointer', marginLeft: '13px' }}
                    />
                    <Box
                      component="img"
                      src="/images/test-database-icon.svg"
                      alt="Icon"
                      sx={{ cursor: 'pointer', marginLeft: '17px' }}
                    />
                    {selectedRow2 && <span style={{ marginLeft: '10px' }}>{selectedRow2.id}</span>}
                  </>
                  : ''}
              </div>
            </p>
          </div>
        </Box>

        <Box mt={1}>
          <Grid container>
            <Grid item xs={12} md={2}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }} className="lockDB">
                {lock && selectedRow && selectedRow2 ? (
                  <Box
                    component="img"
                    src="/images/after-checkbox.svg"
                    alt="Icon"
                    onClick={handleCheckbox}
                    style={{ cursor: 'pointer' }}
                  />
                ) : (
                  <Box
                    component="img"
                    src="/images/before-checkbox.svg"
                    alt="Icon"
                    onClick={handleCheckbox}
                    style={{ cursor: 'pointer' }}

                  />
                )}
                <p style={{
                  cursor: 'pointer',
                  fontSize: '18px',
                  lineHeight: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '18px',
                }} onClick={handleCheckbox}>LOCK DB</p>
                {selectedRow && selectedRow2 && lock ? <>
                  <Tooltip title="you are about to lock a database, which means no other user would be able to copy another DB over it without you unlocking it. Feel free to put a note to support your lock reason!" placement="top" className="tool-tip" arrow>
                    <Box
                      component="img"
                      src="/images/red-error.svg"
                      alt="Icon"
                      sx={{ cursor: 'pointer', marginLeft: '13px' }}
                    />
                  </Tooltip>
                </> : ''}
              </div>
            </Grid>
            <Grid item xs={11} md={9.8}>
              <form>
                <FormControl sx={{ width: '100%', marginLeft: '13px' }}>
                  <OutlinedInput
                    sx={{ backgroundColor: '#fff' }}
                    placeholder="Note"
                    value={note}
                    onChange={handleNote} />
                </FormControl>
              </form>
            </Grid>
          </Grid>
        </Box>

        <Box mt={3}>
          {selectedRow && selectedRow2 ? (
            <Button
              className='rounded-5 text-capitalize'
              type='button'
              onClick={handleOpen}
              variant="contained"
              style={{ backgroundColor: '#BF1D2D', fontSize: '18px', fontWeight: '500', width: '220px', height: '48px' }}
            >
              COPY DATABASE
            </Button>
          ) : (
            <Button
              className='rounded-5 text-capitalize'
              type='button'
              disabled
              variant="contained"
              style={{ backgroundColor: '#D0D5DD', fontSize: '18px', fontWeight: '500', width: '220px', height: '48px' }}
            >
              COPY DATABASE
            </Button>
          )}
        </Box>


        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 380,
              bgcolor: '#f5f5f5',
              border: '1px solid #cccccc',
              boxShadow: 24,
              p: 3,
              borderRadius: '8px',
            }}
          >
            <FormControl sx={{ width: '100%' }} variant="outlined">
              <FormLabel htmlFor="bootstrap-input">#This is Case Sensitive</FormLabel>
              <OutlinedInput
                value={confirmNote}
                sx={{ mt: 2, backgroundColor: '#fff' }}
                id="outlined-adornment-weight"
                placeholder="Type DB name copying to,verify the copy"
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                onChange={handleConfirmNote}
              />
            </FormControl>

            <div className="mt-3" style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
              <Typography
                aria-label="close"
                onClick={handleClose}
                style={{
                  fontSize: '14px',
                  color: '#555555',
                  marginRight: '18px',
                  cursor: 'pointer',
                }}
              >CANCEL</Typography>

              <Button
                onClose={handleClose}
                onClick={submitData}
                className='rounded-5 text-capitalize'
                type='button'
                variant="contained"
                style={{ backgroundColor: '#BF1D2D', fontSize: '14px', fontWeight: '500', width: '110px', height: '38px' }}
              >
                CONFIRM
              </Button>
            </div>
          </Box>
        </Modal>
        <Toast
          snackbarOpen={snackbarOpen}
          handleCloseSnackbar={handleCloseSnackbar}
          message={message}
          type={type}
        />
      </div>
    </>
  );
};

export default CopyDatabase;
